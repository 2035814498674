import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this.element.addEventListener('click', function() {
      this.element.classList.add('animated-plane-activated')
    }.bind(this))

    // Instantly click
    if (this.element.dataset.autoClick == "1") this.element.click()
  }

}