import { Controller } from "stimulus"

export default class extends Controller {
  
  static targets = [ "form" ]

  submit() {
    this.formTarget.requestSubmit()
  }

}