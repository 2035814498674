import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["instructors"]

  connect() {
    if (this.licenseCount > 25) {
      this.instructorsTarget.value = 999
    } else {
      this.instructorsTarget.value = this.licenseCount
    }
  }

  get licenseCount() {
    return localStorage.getItem("licenseCount") || 1
  }

}