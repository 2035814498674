import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["uncheckedContent", "checkedContent", "checkbox"]
  
  toggle() {
    this.checkedContentTarget.classList.toggle("hidden", !this.checked)
    this.uncheckedContentTarget.classList.toggle("hidden", this.checked)
    this.element.classList.toggle("button-outline", !this.checked)
  }
  
  get checked() {
    return this.checkboxTarget.checked
  }
  
}