import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "video", "play", "pause", "controls" ]
  
  play() {
    this.videoTarget.play()
  }
  
  pause() {
    this.videoTarget.pause()
  }
  
  rewind() {
    this.videoTarget.currentTime = 0
  }
  
  toggleButtons() {
    if (this.videoTarget.paused) {
      this.playTarget.classList.remove("hidden")
      this.pauseTarget.classList.add("hidden")
      this.controlsTarget.classList.remove("opacity-0")
    } else {
      this.playTarget.classList.add("hidden")
      this.pauseTarget.classList.remove("hidden")
      this.controlsTarget.classList.add("opacity-0")
    }
  }
  
}