import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["navigation"]
  
  connect() {
    document.addEventListener("scroll", this.scroll.bind(this), {passive: true})
  }
  
  disconnect() {
    document.removeEventListener("scroll", this.scroll.bind(this))
  }

  changeHeaderColors = throttle(function() {
    this.sections.forEach(function(section) {
      let box = section.getBoundingClientRect()
      if (box.y <= 50 && (box.y + box.height) >= 50)
        this.element.dataset.bgColor = section.dataset.bgColor
    }.bind(this))
  }, 100)

  scroll(event) {
    this.changeHeaderColors()
  }

  toggleNav(event) {
    event.preventDefault()
    this.navigationTarget.classList.toggle('open')
  }

  get sections() {
    return Array.from(document.querySelectorAll("[data-bg-color]"))
  }

}