import { Controller } from "stimulus"

require('cleave.js/dist/cleave.js')

export default class extends Controller {
  static targets = [ "postalCode" ]

  connect() {
    this.open()
    if (this.hasPostalCodeTarget) {
      this.cleavePostalCodeField()
    }
  }

  open() {
    setTimeout(function() {
      this.element.classList.add('open')
    }.bind(this), 100)
  }

  close() {
    document.querySelector('#modal').innerHTML = ""
    document.querySelectorAll('.animated-plane-activated').forEach(function(button) {
      button.classList.remove('animated-plane-activated')
    })
  }

  preventClose(event) {
    event.stopPropagation()
  }

  cleavePostalCodeField() {
    let params = {blocks: [4, 2], uppercase: true, delimiter: ' '}

    // Reset cleave instance
    if (this.cleaveInstance) this.cleaveInstance.destroy()
    this.cleaveInstance = new Cleave(this.postalCodeTarget, params)
  }

}