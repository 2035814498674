import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "selectable" ]
  
  select(event) {
    this.selectableTargets.forEach(function(selectable) {
      selectable.classList.remove(...this.classes)
    }.bind(this))
    event.currentTarget.classList.add(...this.classes)
  }
  
  get classes() {
    return this.element.dataset.selectableClass.split(" ")
  }
  
}