import { Controller } from "stimulus"

export default class extends Controller {

  openIntercom() {
    Intercom('show');
  }

}

