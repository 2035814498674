import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "total", "totalSub", "fulltime", "parttime", "fulltimeLicenses", "parttimeLicenses", "parttimeGroup", "theoryExplainer", "theoryPrice" ]

  connect() {
    this.fulltimeTarget.value = localStorage.getItem('fulltimeCount') || 1
    this.parttimeTarget.value = localStorage.getItem('parttimeCount') || 0
    
    this.theory = false
    this.homework = false
    
    this.calculatePrice()
  }
  
  track() {
    gtag('event', 'pricing', {'price': this.price})
  }

  calculatePrice(event) {
    // Set license counters
    this.fulltimeLicensesTarget.innerText = this.fulltime
    this.parttimeLicensesTarget.innerText = this.parttime

    // Set total price
    this.totalTarget.innerText = this.price
    
    // Set theory price
    let instructors = this.fulltime + this.parttime
    let studentsperyear = this.fte * 40 - 5
    let theorypricing = (this.theoryPrice * 12) / (studentsperyear)
    this.theoryPriceTarget.innerText = theorypricing.toFixed(2).replace(".", ",").replace(",00", ",-")
    
    if (instructors == 1) {
      this.theoryExplainerTarget.innerText = `Jouw rijschool heeft ${instructors} instructeur en per jaar leid je gemiddeld ${studentsperyear} leerlingen op.`
    } else {
      this.theoryExplainerTarget.innerText = `Jouw rijschool heeft ${instructors} instructeurs en per jaar leiden jullie samen gemiddeld ${studentsperyear} leerlingen op.`
    }

    // Toggle active class
    this.parttimeGroupTarget.classList.toggle("pricing-slider-inactive", this.parttime == 0)

    // Set localStorage
    localStorage.setItem('fulltimeCount', this.fulltime)
    localStorage.setItem('parttimeCount', this.parttime)
    localStorage.setItem('licenseCount', this.licenses)
  }
  
  toggleTheory(event) {
    this.theory = event.currentTarget.checked
    this.calculatePrice()
  }
  
  toggleHomework(event) {
    this.homework = event.currentTarget.checked
    this.calculatePrice()
  }
  
  get fte() {
    return this.fulltime + this.parttime * 0.5
  }

  get price() {
    let price = this.fixedRate + this.fulltime * this.fulltimeRate + this.parttime * this.parttimeRate
    price = Math.round(price * 100) / 100
    
    if (this.theory) {
      price = price + this.theoryPrice
    }
    
    if (this.homework) {
      price = price + this.homeworkRate
    }
    
    if (price > 450) {
      this.totalSubTarget.classList.add('hidden')
      return "Op aanvraag"
    } else {
      this.totalSubTarget.classList.remove('hidden')
      return "€" + price.toFixed(2).replace(".", ",").replace(",00", ",-")
    }      
  }

  get fulltime() {
    return parseInt(this.fulltimeTarget.value)
  }

  get parttime() {
    return parseInt(this.parttimeTarget.value)
  }

  get licenses() {
    return this.fulltime + this.parttime
  }

  get fulltimeRate() {
    if (this.licenses <= 4) {
      return 45
    } else if (this.licenses <= 9) {
      return 33
    } else if (this.licenses <= 14) {
      return 28
    } else if (this.licenses <= 19) {
      return 23.5
    } else if (this.licenses <= 22) {
      return 21.5
    } else if (this.licenses <= 23) {
      return 21
    } else if (this.licenses <= 24) {
      return 20.5
    } else if (this.licenses <= 25) {
      return 20
    } else if (this.licenses <= 26) {
      return 19.5
    } else {
      return 19
    }
  }

  get parttimeRate() {
    return Math.round(this.fulltimeRate * 0.55 * 100) / 100
  }

  get fixedRate() {
    if (this.licenses <= 4) {
      return 0
    } else if (this.licenses <= 9) {
      return 30
    } else if (this.licenses <= 14) {
      return 60
    } else if (this.licenses <= 19) {
      return 100
    } else if (this.licenses <= 25) {
      return 120
    } else {
      return 150
    }
  }
  
  get theoryPrice() {
    let theoryPrice = this.theoryFixedRate + this.fulltime * this.theoryFulltimeRate + this.parttime * this.theoryParttimeRate
    return Math.round(theoryPrice * 100) / 100
  }
  
  get theoryFixedRate() {
    if (this.licenses <= 4) {
      return 0
    } else if (this.licenses <= 9) {
      return 20
    } else if (this.licenses <= 14) {
      return 40
    } else if (this.licenses <= 19) {
      return 60
    } else {
      return 80
    }
  }
  
  get theoryFulltimeRate() {
    if (this.licenses <= 4) {
      return 40
    } else if (this.licenses <= 9) {
      return 30
    } else if (this.licenses <= 14) {
      return 25
    } else if (this.licenses <= 19) {
      return 21
    } else {
      return 17
    }
  }
  
  get theoryParttimeRate() {
    return Math.round(this.theoryFulltimeRate * 0.55 * 100) / 100
  }
  
  get homeworkRate() {
    return Math.round(this.fte * 6 * 100) / 100
  }

}