// Remember scroll position
(function(){
  var scrollPositions = {};

  addEventListener("turbo:before-render", function(){
    document.querySelectorAll("[data-turbo-permanent]").forEach(function(element){ 
      scrollPositions[element.id] = element.scrollTop;
    });
  });

  addEventListener("turbo:render", function(){
    document.querySelectorAll("[data-turbo-permanent]").forEach(function(element){ 
      element.scrollTop = scrollPositions[element.id];
    });
  });
})();

window.throttle = function(callback, wait, immediate = false) {
  let timeout = null 
  let initialCall = true
  
  return function() {
    const callNow = immediate && initialCall
    const next = () => {
      callback.apply(this, arguments)
      timeout = null
    }
    
    if (callNow) { 
      initialCall = false
      next()
    }

    if (!timeout) {
      timeout = setTimeout(next, wait)
    }
  }
}

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

import "./application.css"

// Boot
require("@rails/ujs").start()
import "@hotwired/turbo"

const application = Application.start()
const controllers = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(controllers))