import { Controller } from "stimulus"

export default class extends Controller {
  
  navigate(event) {
    let value = this.element.value
    if (value.length > 0) {
      window.location = value
    }
  }
  
}