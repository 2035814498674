import { Controller } from "stimulus"
import Plyr from "plyr"

export default class extends Controller {

  connect() {
    let player = new Plyr(this.element, {controls: this.controls, autoplay: this.autoplay})
    
    // Track video play
    gtag('event', 'video_play', {'embed_id': this.embedId})
  }
  
  get autoplay() {
    return this.element.hasAttribute("autoplay")
  }

  get controls() {
    return ["play", "play-large", "progress", "fullscreen"]
  }
  
  get embedId() {
    return this.element.dataset.plyrEmbedId
  }

}