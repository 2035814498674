import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "message" ]
  
  connect() {
    document.addEventListener("scroll", this.animate.bind(this), {passive: true})
  }
  
  disconnect() {
    document.removeEventListener("scroll", this.animate.bind(this))
  }

  transformMessage = throttle(function() {
    this.messageTarget.style.transform = `translateY(${this.percentageInView - 50}%)`
  }, 16)

  animate() {
    this.transformMessage()
  }

  get percentageInView() {
    let box = this.messageTarget.getBoundingClientRect()
    return Math.min(Math.max(box.y / window.innerHeight * 100, 0), 100)
  }

}
