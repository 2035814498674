import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "video" ]

  step1() {
    this.videoTarget.currentTime = 0
  }

  step2() {
    this.videoTarget.currentTime = 14
  }

  step3() {
    this.videoTarget.currentTime = 30
  }

}